<template>
  <div class="template-3">
    <div class="section position-relative" :style="data.background_style||'#fff'">
      <div class="elementor-shape elementor-shape-top">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            class="elementor-shape-fill"
            d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
          ></path>
        </svg>
      </div>
      <div class="section-content d-flex align-items-center">
        <div class="row mx-0 w-100">
          <div class="col-6">
            <h3 :class="{ 'text-white': data.title }">
              {{ data.title || "Title" }}
            </h3>
            <p :class="{ 'text-white': data.content }">
              {{ data.content || "Content" }}
            </p>
          </div>
          <div class="col-6">
            <div class="img-container">
              <img
                width="200px"
                class="img"
                :src="data.image_1   |convertBLOB "
              />
              <div v-if="!data.image_1" class="centered">Image 1</div>
            </div>
          </div>
        </div>
      </div>
      <div class="elementor-shape elementor-shape-bottom">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
	<path class="elementor-shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
</svg>		</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Template-3",
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
  filters:{
    convertBLOB(blob){
      return blob
        ? typeof blob === "string"
          ? blob
          : URL.createObjectURL(blob)
        : "/img/image_placeholder.png";
    }
  }
};
</script>
<style lang="scss" scoped>
.section-content {
  min-height: 300px;
}
.section {
  background-color: transparent;
  // background-image: linear-gradient(130deg, #00b9de 0%, #1c3788 89%);
}
.elementor-shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
}
// .elementor-6292
//   .elementor-element.elementor-element-ef414cd
//   > .elementor-shape-top
//   svg {
//   width: calc(230% + 1.3px);
//   height: 100px;
// }
.elementor-shape .elementor-shape-fill {
  fill: #fff;
  transform-origin: center;
  transform: rotateY(0deg);
}

.img {
  object-fit: cover;
}
.img-container {
  position: relative;
  text-align: center;
  color: #202020;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.elementor-shape-bottom {
    bottom: -1px;
}
.elementor-shape.elementor-shape-bottom{
    transform: rotate(180deg);
}
</style>
