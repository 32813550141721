<template>
  <div class="template-2">
    <div class="section d-flex align-items-center" :style="data.background_style||'#fff'">
      <div class="row mx-0 w-100">
        <div class="col-6">
          <div class="img-container">

          <img width="200px" height="250px" class="img" :src="data.image_1  |convertBLOB " />
          <div v-if="!data.image_1" class="centered">Image 1</div>
          </div>
        </div>
        <div class="col-6">
          <h3 :class="{'text-primary':data.title}">{{ data.title || "Title" }}</h3>
          <p :class="{'text-gray':data.content}">{{ data.content || "Content" }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Template-2",
  props: {data:{
    type: Object,
    default:{}
  }},
  filters:{
    convertBLOB(blob){
      return blob
        ? typeof blob === "string"
          ? blob
          : URL.createObjectURL(blob)
        : "/img/image_placeholder.png";
    }
  }
};
</script>
<style lang="scss" scoped>
.section {
  min-height: 300px;
  // background-color: #fff;

  background-image: url(https://specialistlanguagecourses.com/wp-content/uploads/2021/03/BG-home-new4-1.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;
}
.img {
  border-radius: 100px 100px 300px 100px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.12);
  object-fit: cover;
}
.img-container {
  position: relative;
  text-align: center;
  color: #202020;

}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
